<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
    <!-- 탱크재고 조회 --------------------------------------------------------------------------------------------->

    <BCard header-tag="header" footer-tag="footer">

      <div slot="header">
        <BIconServer/> <strong> 패킷 모니터 </strong>
        <div class="card-header-actions">
          <small class="text-muted">실시간 패킷을 모니터합니다</small>
        </div>
      </div>

      <BRow class="mb-2">
        <BCol>
          <BInputGroup size="sm">
            <BInputGroupAppend>
              <BFormSelect size="sm" v-model="tid"
                           style="background-color: #2f303a"
                           :options="tankOpts"
                           @input="selectTank"/>
              <BButton size="sm" variant="info" @click="gotoMonitor('', tid)" :disabled="!tid">
                <BIconBack/> 탱크 모니터링
              </BButton>
            </BInputGroupAppend>
          </BInputGroup>
        </BCol>
        <BCol>
          <BInputGroup v-if=false size="sm">
            <BInputGroupAppend>
              <BFormSelect size="sm"
                           style="background-color: #24252F"
                           v-model="arCode"
                           :options="areaOpts"/>

            </BInputGroupAppend>
          </BInputGroup>
        </BCol>
        <BCol sm="2" class="text-right">
          <BInputGroup size="sm">
            <BFormSelect size="sm" v-model="numOfRecord" :options="[10,15,20,30,50,100]" style="background-color: #2f303a"/>
            <BInputGroupAppend>
              <BButtonGroup>
                <BButton size="sm" variant="primary" @click="getTankData"><BIconArrowRepeat/></BButton>
              </BButtonGroup>
            </BInputGroupAppend>
          </BInputGroup>
        </BCol>
      </BRow>


      <BRow>
        <BCol style="min-height:400px">
          <vue-excel-editor
            v-model="rows"
            ref="excelGrid"
            width="100%"
            class="mb-1"
            :page="numOfRecord"
            :readonly-style="{backgroundColor:'#4F5040'}"
            @select="selectRecord"
            :localized-label="editorLabel"
            filter-row>
            <vue-excel-column field="_id"        type="string"   key-field invisible/>
            <vue-excel-column field="dt"         type="string"   width="150px" :label="colNm['dt']" :to-text="toLocalTimeSec" readonly/>
            <vue-excel-column field="mid"        type="string"   width="80px" :label="colNm['mid']" readonly/>
            <!--                <vue-excel-column field="tid"        type="string"   width="80px" :label="colNm['tid']" readonly/>-->
            <vue-excel-column field="acd"        type="map"      width="70px" :label="colNm['acd']" :options="arCodeMap" readonly />
            <!--                <vue-excel-column field="use"        type="string"   width="80px" :label="colNm['use']" readonly/>-->
            <vue-excel-column field="ohr"        type="number"   width="72px" :label="colNm['ohr']" :to-text="toCommaInt" readonly/>
            <vue-excel-column field="whr"        type="number"   width="72px" :label="colNm['whr']" :to-text="toCommaInt" readonly/>
            <vue-excel-column field="ov"         type="number"   width="72px" :label="colNm['ov']"  :to-text="toCommaInt" readonly/>
            <vue-excel-column field="wv"         type="number"   width="72px" :label="colNm['wv']"  :to-text="toCommaInt" readonly/>
            <vue-excel-column field="ovm"        type="number"   width="72px" :label="colNm['ovm']" :to-text="toCommaInt" readonly/>
            <vue-excel-column field="ovc"        type="number"   width="72px" :label="colNm['ovc']" :to-text="toCommaInt" readonly/>
            <vue-excel-column field="ovcr"       type="number"   width="72px" :label="colNm['ovcr']" :to-text="toCommaInt" readonly/>
            <vue-excel-column field="tvp"        type="number"   width="72px" :label="colNm['tvp']" readonly/>
            <vue-excel-column field="avgTm"      type="number"   width="72px" :label="colNm['avgTm']" readonly/>
            <vue-excel-column field="minTm"      type="number"   width="72px" :label="colNm['minTm']" readonly/>
            <vue-excel-column field="maxTm"      type="number"   width="72px" :label="colNm['maxTm']" readonly/>
            <vue-excel-column field="tm1"        type="number"   width="60px" :label="colNm['tm1']" readonly/>
            <vue-excel-column field="tm2"        type="number"   width="60px" :label="colNm['tm2']" readonly/>
            <vue-excel-column field="tm3"        type="number"   width="60px" :label="colNm['tm3']" readonly/>
            <vue-excel-column field="tm4"        type="number"   width="60px" :label="colNm['tm4']" readonly/>
            <vue-excel-column field="tm5"        type="number"   width="60px" :label="colNm['tm5']" readonly/>
            <vue-excel-column field="tm6"        type="number"   width="60px" :label="colNm['tm6']" readonly/>
            <vue-excel-column field="isEvt"      type="map"      width="60px" :label="colNm['isEvt']" :options="{true:'Y', false:''}" readonly/>

            <!--                <vue-excel-column field="tm7"        type="number"   width="80px" :label="colNm['tm7']" readonly/>-->
            <!--                <vue-excel-column field="tm8"        type="number"   width="80px" :label="colNm['tm8']" readonly/>-->
            <!--                <vue-excel-column field="tm9"        type="number"   width="80px" :label="colNm['tm9']" readonly/>-->
            <vue-excel-column field="rsi"        type="number"   width="80px" :label="colNm['rsi']" readonly/>
            <vue-excel-column field="btr"        type="number"   width="80px" :label="colNm['btr']" readonly/>
            <vue-excel-column field="tv"         type="number"   width="80px" :label="colNm['tv']" :to-text="toCommaInt" readonly/>
            <vue-excel-column field="trv"        type="number"   width="80px" :label="colNm['trv']" :to-text="toCommaInt" readonly/>

            <vue-excel-column field="messages"   type="string"   width="200px" :label="colNm['text']" readonly/>
            <vue-excel-column field="encStr"   type="string"   width="600px" :label="colNm['encStr']" readonly/>
            <vue-excel-column field="decStr"   type="string"   width="600px" :label="colNm['decStr']" readonly/>
            <vue-excel-column field="isErr"      type="map"      width="40px" :label="colNm['isErr']" :options="{true:'Y', false:''}" readonly/>

          </vue-excel-editor>
        </BCol>
      </BRow>

      <BRow>
        <BCol>
          <vue-excel-editor
            v-model="evtRows"
            ref="evtGrid"
            width="100%"
            class="mb-1"
            :page="numOfRecord"
            :readonly-style="{backgroundColor:'#4F5040'}"
            :localized-label="editorLabel"
            filter-row>
            <vue-excel-column field="_id"        type="string"   key-field invisible/>
            <vue-excel-column field="tid"        type="string"   width="80px" label="탱크번호" readonly/>
            <vue-excel-column field="code"       type="string"   width="40px" label="코드" readonly/>
            <vue-excel-column field="code"       type="map"     width="80px" label="코드명" :options="eventMap" readonly/>
            <vue-excel-column field="text"       type="string"   width="1000px" label="이벤트 내용" readonly/>

          </vue-excel-editor>

        </BCol>
      </BRow>

    </BCard>



  </div>
</template>



<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';

import {
  apiCall, modalSuccess, modalWarn, sleep, speech,
} from '@/common/utils';
import qs from 'querystring';
// import moment from "moment";
import {ExcelEditorLabel, TankUseNameMap} from '@/common/constants'

const pktColName = {
  dt            : '측정일시',   // T_CREATE_DATE
  tid           : '탱크번호', // T_TANK 탱크아이디
  mid           : '장비번호',
  acd           : '지역', // Work Group Code (지역코드)
  am            : '측정구분', // T_TYPE =  A=ATG,M=MTG
  ocd           : '유종코드', // T_OIL 유종코드
  use           : '사용용도', // T_USE 사용용도
  tv            : '탱크용량', // T_MAX_VOLUME = tank.tankVolume
  tvp           : '충족률', // T_MAX_VOLUME = tank.tankVolume
  trv           : '실용량', // T_REAL_VOLUME = tank.tankRealVol
  ohr           : '유위', // T_OIL_HEIGHT = tlgPacket.ohr = 유위(보정높이)
  whr           : '수위', // T_WATER_HEIGHT = tlgPacket.whr = 수위(보정높이)
  ov            : '유량', // T_OIL_VOLUME = tlgPacket.ov = 유량
  wv            : '수분', // T_WATER_VOLUME = tlgPacket.wv = 수분량
  ovm           : '재고', // T_MEASURE_VOLUME = tlgPacket.ovm = 실재고
  ovc           : '환산재고', // T_CONVERSION = tlgPacket.ovc = 체적 환산유량
  ovcr          : '보정재고', // T_REVISION = tlgPacket.ovc = 체적 환산유량 + 보정
  tm1           : '온도1', // T_TEMP1 = tlgPacket.tm1
  tm2           : '온도2', // T_TEMP2 = tlgPacket.tm2
  tm3           : '온도3', // T_TEMP3 = tlgPacket.tm3
  tm4           : '온도4', // T_TEMP4 = tlgPacket.tm4
  tm5           : '온도5', // T_TEMP5 = tlgPacket.tm5
  tm6           : '온도6', // T_TEMP6 = tlgPacket.tm6
  tm7           : '온도7', // T_TEMP7 = tlgPacket.tm7
  tm8           : '온도8', // T_TEMP8 = tlgPacket.tm8
  tm9           : '온도9',
  avgTm         : '평균온도', // T_AVERAGE_TEMP
  maxTm         : '최고온도', // T_MAX_TEMP
  minTm         : '최저온도', // T_MIN_TEMP
  btr           : '배터리', // T_BATTERY = tlgPacket.btr
  rsi           : '신호세기', // T_RSSI = tlgPacket.rsi
  encStr        : '암호문',
  decStr        : '평문',
  messages      : '메시지', // T_COMMENT
  isEvt         : '이벤트',
  isErr         : '에러',
};

// const recvTypeMap = { manual: "매뉴얼", api: "API", socket: "소켓" };


//----------------------------------------------------------------------------------------------------
export default {
  name: "TankRealTime",
  components: {
  },
  data () {
    return {
      evtRows: [],
      tp: null,
      arCode: '',
      tanks: [],
      tid: '',
      colNm: pktColName,
      tankOpts : [],
      darkGray: '#181924',
      darkGray2: '#24252F',
      selectedTank: null,
      selectedMachineId: null,
      tank : null,
      tlgPacket: {},
      tankInstances : {},

      editorLabel: ExcelEditorLabel,
      downloadModalShow: false,
      machineMap  : {},
      oilCodeMap  : {},
      arCodeMap  : this.$store.state.area['map'],
      areaOpts  : this.$store.state.area['opts2'],
      tankCodes : this.$store.state.tanks['codes'],
      eventMap : this.$store.state.codeMaps['EVENT'],

      downloadReason: '',
      searchOpts: [
        {value:'tid',        text:'탱크아이디'},
        {value:'name',       text:'탱크명'},
        {value:'arCode',     text:'지역코드'},
      ],
      searchField: 'tid',
      searchWord : '',
      progCount: 0,
      totalCount: 0,
      rows: [],

      paramMap: {},
      numOfRecord: 10,
      updateKey: 0,

      modalTitle: '',


      socket: this.$store.state.socket,
      listenerNameMap: {},
      listenerNames: [],

    }

  },
  async created(){
    try{

      console.log("--- Packet creating ---------------------");

    }catch(err){
      console.log(err);
    }
  },

  computed: {},

  async mounted() {
    console.log("--- Packet mounting---------------------");
    await this.getTanks();

    await sleep(3000);

    await this.setSocketListener();

    // await this.getTankData();

    // below is not work!
  },

  beforeDestroy(){
    // using "removeListener" here, but this should be whatever $socket provides
    // for removing listeners
    if(this.socket) {
      for( const lnm of this.listenerNames){
        this.socket.removeListener(lnm, this.atgDataHandler);
        console.warn("[Packet] beforeDestroy ____________ remove Handler ____________", lnm );
      }

      this.socket.removeListener('disconnect' );
      this.socket.removeListener('connect' );
    }
  },

  methods: {


    gotoMonitor(arCode, tankId='') {

      let code = arCode? arCode: this.arCode;
      let tid = tankId? tankId: '';
      let mode = tid? 'tank':'area';

      let query = `mode=${mode}&arCode=${code}&tid=${tid}`;
      console.log( '%%%%%%%%%%%%%%%% gotoMonitor ------------- store.state.user ---->', this.$store.state.user );
      let width=930;
      let winName = code;
      if(tid) {
        width = 514;
        winName = tid;
      }

      let route = this.$router.resolve({ path: `/monitoring?${query}`});
      const features = `left=0,top=0,width=${width},height=545,status=no,titlebar=no,location=no,menubar=no,toolbar=no`
      const newWin = window.open(route.href, `monitor_${winName}`, features );
      newWin.storedUser = localStorage.user;
      console.log(  'stored user --->', newWin.storedUser );

    },



    atgDataHandler(data){
      // console.log(`TankRealTime---atg-data emitted --- data [${data.payload.tid}] --->`, data.payload.dt )
      let tp = data.payload;

      console.log( 'tp.tid -----> ', tp.tid );
      // console.log( 'this.tid ---> ', this.tid );

      if( tp.tid===this.tid){
        this.rows.push(tp);
        if(tp.isEvt || tp.isErr ) {
          if(tp.isEvt || tp.isErr ) this.evtRows.push( ...tp.events );
        }
      }

      if( !this.tid ) {
        this.rows.push(tp);
        if(tp.isEvt || tp.isErr ) this.evtRows.push( ...tp.events );
      }

      if( this.rows.length > this.numOfRecord) {
        this.rows.shift();
      }

      if( this.evtRows.length > this.numOfRecord) {
        this.evtRows.shift();
      }

    },

    async selectTank(){
      console.log( 'selected tid ---->', this.tid )
      this.rows = [];
      this.evtRows = [];
    },

    async getTankByTid(){
      try{

        this.tank = null;
        const r = await apiCall('get', `/api/tank/tid/${this.tid}`);
        console.log( r );

        if(r.code===200){
          this.tank = r.result;
          await this.toastInfo(`${this.tank.name} 조회됨`, 'info');
          // console.log( 'task-data-query-result--->', r.result  );
        }

      }catch(err){
        // console.log( 'getTankList----------------->', err );
        this.toastError(err);
      }

    },

    async getTanks(){
      try{

        this.tank = null;
        this.tankOpts = [{value: '', text: '전체 탱크'}];
        const r = await apiCall('get', `/api/tank?order=arCode`);
        if(r.code===200){
          r.result.map(t=>{

            if(t.display){

              this.tanks.push(t);
              this.tankOpts.push({ value: t.tid, text: `[${t.tid}] ${t.name}`} );

              this.listenerNameMap[t.tankUse] = TankUseNameMap[t.tankUse];
            }
          })
          // console.log( 'listenerNameMap -------->', this.listenerNameMap );
          Object.keys(this.listenerNameMap).map(k=>{ this.listenerNames.push(this.listenerNameMap[k]) });

          await this.toastInfo(`탱크정보 ${this.tanks.length}건 조회`, 'info');

          // console.log( 'task-data-query-result--->', r.result  );
        }

      }catch(err){
        // console.log( 'getTankList----------------->', err );
        this.toastError(err);
      }

    },




    async getTankData(){
      try{
        if(!this.tid){
          this.tid = this.tankOpts[1].value;
        }
        let qry = '';
        this.paramMap = {};
        if(this.searchWord) this.paramMap[this.searchField] = this.searchWord;
        qry = qs.stringify( this.paramMap );
        console.log( "query-string ---------- ", qry );


        this.rows = [];
        const r = await apiCall('get', `/api/inventory/tank/${this.tid}`);

        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`${this.rows.length}건 조회됨`, 'info');
          // console.log( 'task-data-query-result--->', r.result  );
        }
        // this.$refs['excelGrid'].clearFilter();
        this.selectedTank = null;
      }catch(err){
        // console.log( 'getTankList----------------->', err );
        this.toastError(err);
      }

    },



    async selectRecord(idx, evt){
      if(!evt){
        this.selectedTank = null;
        this.selectedMachineId = null;
        return;
      }

      this.selectedTank = this.$refs['excelGrid'].getSelectedRecords().pop();
      this.selectedMachineId = (this.selectedTank.machine)?this.selectedTank.machine._id: null;

      console.log( 'select idx, tank --->', idx, this.selectedTank );

    },

    async setSocketListener(){
      try{
        console.warn("[Packet] ############ register atgDataHandler #########");
        this.socket = this.$store.state.socket;
        if(this.socket) {

          this.socket.removeListener('disconnect' );
          this.socket.removeListener('connect' );

          this.socket.on('disconnect', ()=>{
            // speech('ATG 서버와 통신이 유실 되었습니다. ,,화면을 종료하고 재접속 하시기 바랍니다.');
            speech('서버와 통신이 유실 되었습니다.');
            // modalWarn(this.$bvModal, '서버와 통신이 유실 되었습니다.');
            this.$store.state.serverConnected = false;
            // alert("[WARN] ATG 서버와 통신이 유실되었습니다. 모니터링을 종료합니다.")
            // window.close();
          });
          this.socket.on('connect', ()=>{
            speech('서버와 통신이 연결 되었습니다.');
            // modalSuccess(this.$bvModal, '서버와 통신이 연결 되었습니다.');
            this.$store.state.serverConnected = true;
          });

          console.warn( "will register listeners --- names --->", this.listenerNames );

          for( const lnm of this.listenerNames){
            this.socket.removeListener(lnm, this.atgDataHandler);
            this.socket.on( lnm, this.atgDataHandler );
            console.warn("[Packet] ____________ register Handler ____________", lnm ,"___________________")
          }
        }
        console.warn( "[Packet] ############ socket object ------> is connecting? ", this.socket.connected );
      }catch(err){
        console.error(err);
      }
    },

  }
}
</script>
